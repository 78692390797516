var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.supplier_staffList,"items-per-page":-1,"hide-default-footer":"","fixed-header":"","height":_vm.list_height + 'px'},on:{"dblclick:row":function ($event, ref) {
	var item = ref.item;

	return _vm.rowClick(item);
}},scopedSlots:_vm._u([{key:"item.kana",fn:function(ref){
var item = ref.item;
return [(item.kana)?_c('div',[_vm._v(" "+_vm._s(item.kana)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('div',[_vm._v(" "+_vm._s(item.email)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}},{key:"item.tel",fn:function(ref){
var item = ref.item;
return [(item.tel)?_c('div',[_vm._v(" "+_vm._s(item.tel)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}}],null,true)}),_c('div',{staticClass:"text-center pt-3",attrs:{"id":"pagination"}},[_c('v-pagination',{attrs:{"length":_vm.pagination.last_page},on:{"input":function($event){return _vm.paramsToQuery()}},model:{value:(_vm.requestParams.page),callback:function ($$v) {_vm.$set(_vm.requestParams, "page", $$v)},expression:"requestParams.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }